import { template as template_9ab3def8c2c949659f8c8ae342e13aa9 } from "@ember/template-compiler";
const FKControlMenuContainer = template_9ab3def8c2c949659f8c8ae342e13aa9(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

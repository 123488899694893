import { template as template_7965371e77b243fa8d197aee26225da6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_7965371e77b243fa8d197aee26225da6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

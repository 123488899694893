import { template as template_36d3e5b9ab0a4e9db6c983b22b2f8d58 } from "@ember/template-compiler";
const FKText = template_36d3e5b9ab0a4e9db6c983b22b2f8d58(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
